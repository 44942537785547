<template>
  <div class="drop" @dragover.prevent @drop="onDrop">
    <label class="btn display-inline">
      PRZECIĄGNIJ I UPUŚĆ LUB
      <input type="file" name="value" ref="fileInput" @change="onChange" />
    </label>
  </div>
</template>

<script>
export default {
  props: ['extension'],
  methods: {
    onDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      let files = e.dataTransfer.files
      this.$emit('submit', files[0])
    },
    onChange(e) {
      let files = e.target.files
      const file = files[0]
      if (this.extension && this.extension.length) {
        const fileExtension = '.' + file.name.split('.').pop()
        if (!this.extension.includes(fileExtension)) {
          this.$message({
            message: `Nie możesz użyć tego zdjęcia, ponieważ nie jest plikiem o rozszerzeniu: ${this.extension.join(
              ' lub '
            )}.`,
            type: 'error'
          })
          this.clear()
          return
        }
      }
      const maxSize = 2 * 1024 * 1024;
      const fileSizeFormat = (file.size / 1024 / 1024).toFixed(2);
      const maxSizeFormat = (maxSize / 1024 / 1024).toFixed(2);
      if (file.size > maxSize)
      {
        this.$message({
          message: `Zdjęcie zbyt duże: ${fileSizeFormat} MB. Maksymalny rozmiar to ${maxSizeFormat} MB`,
          type: 'error'
        })
        this.clear()
        return
      }

      this.$emit('submit', file)
    },
    clear() {
      this.$refs.fileInput.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.drop {
  border: 2px dashed #ccc;
  padding: 40px;
  background-color: #fafafa;
  height: 100%;
  position: relative;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;
}

.drop img {
  max-width: 100%;
}
</style>
