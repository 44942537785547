<template>
  <div>
    <images-dialog
      ref="imagesDialog"
      @submit="dialogSubmitted"
      :extension="extension"
    />
    <image-edit-dialog
      ref="imageEditDialog"
      :key="value"
      @formSubmitted="fetchSelectedImage"
    />
    <div class="box">
      <img
        v-if="selectedImage"
        class="thumbnail"
        :src="src"
        :alt="selectedImage.title"
      />
      <div v-if="fileSize" class="size" :class="{'size-error': fileSize > 2}">
        Rozmiar - {{fileSize.toFixed(2)}}MB
      </div>
      <div v-if="fileSize > 0.5" class="warning">
        <p class="icon">⚠️</p>
        Zdjęcie jest większe niż 0.5 MB. Rozważ zmniejszenie zdjęcia aby nie powodować problemów z wydajnością strony.
      </div>
      <div class="image-info">
        <p class="image-info__title" v-if="selectedImage">
          {{ selectedImage.title }}
        </p>
        <el-button @click="openDialog" type="info" class="open-dialog-button">
          <i class="fa fa-file-image-o"></i> Wybierz lub prześlij zdjęcie
        </el-button>
        <el-button v-if="selectedImage" @click="openEditImageDialog" type="primary" class="open-dialog-button">
          <i class="fa fa-file-image-o"></i> Edytuj zdjęcie
        </el-button>
      </div>
      <el-button
        @click.stop="removeImage"
        class="remove-button"
        v-if="value"
        size="mini"
        icon="el-icon-delete"
        type="danger"
        title="Usuń zdjęcie"
      >
      </el-button>
      <el-form-item v-if="selectedImage" label="Nie kompresuj zdjęcia" class="keep_original_switch">
        <el-switch @change="onKeepOriginalChange" v-model="keepOriginal" />
      </el-form-item>
    </div>
  </div>
</template>
<script>
import ImagesDialog from '@/components/inputs/ImageDialog/'
import ImageEditDialog from '@/components/inputs/ImageDialog/ImageEditDialog'
import { Image } from '@/api'

export default {
  props: ['value', 'extension'],
  components: {
    ImagesDialog,
    ImageEditDialog
  },
  data() {
    return {
      selectedImage: null,
      keepOriginal: false,
      fileSize: 0,
    }
  },
  methods: {
    removeImage() {
      this.$emit('input', null)
    },
    openDialog() {
      this.$refs.imagesDialog.openDialog()
    },
    dialogSubmitted(image) {
      this.$emit('input', image)
    },
    fetchSelectedImage() {
      Image.get({ id: this.value }).then(response => {
        this.selectedImage = response.data
        this.keepOriginal = this.selectedImage.keep_original
        this.updateFileSize()
      })
    },
    openEditImageDialog() {
      this.$refs.imageEditDialog.openDialog(this.value)
    },
    onKeepOriginalChange() {
      Image.update({ id: this.value }, { keep_original: this.keepOriginal })
          .then(response => {
            this.selectedImage = response.data
          })
    },
    updateFileSize() {
      if (this.selectedImage) {
        fetch(this.selectedImage.file_url, {
          method: "GET",
          headers: {
          },
        }).then(response => {
          this.fileSize = Number.parseFloat(response.headers.get("content-length"))
          this.fileSize = this.fileSize / 1024 / 1024
        });
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        value ? this.fetchSelectedImage() : (this.selectedImage = null)

        this.updateFileSize()
      }
    }
  },
  computed: {
    src() {
      const thumbnail = this.selectedImage.thumbnail
      return thumbnail ? thumbnail.url : this.selectedImage.file_url
    },
  }
}
</script>
<style lang="scss" scoped>
.warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.size {
  font-size: 14px;
  font-weight: bold;
}
.size-error {
  color: red;
}
.icon {
  font-size: 48px;
  margin: 0;
}
.remove-button {
  position: absolute;
  right: 28px;
  top: 28px;
}

.keep_original_switch {
  position: absolute;
  right: 100px;
  top: 28px;
}

.open-dialog-button {
  vertical-align: middle;
}

.thumbnail {
  max-width: 140px;
  vertical-align: middle;
}

.image-info {
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;

  &__title {
    font-weight: 700;
  }

  &__edit-image-icon {
    cursor: pointer;
    vertical-align: middle;
  }
}
</style>
